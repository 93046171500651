import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/app-dev.json';

const ServiceMobileContent = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">


                        <div className="services-details-desc">
                            <span className="sub-title">Développement d'application mobile</span>
                            <h3>Une application mobile pour votre entreprise</h3>
                            <p>Améliorez l'efficacité de vos employés avec une application embarquée ou offrez une expérience supérieure à vos clients avec une application mobile personnalisée sur Android et iOS. Une fois le cahier des charges établi, nous élaborons votre application en vous fournissant des rapports d'avancement afin que vous puissiez toujours vous assurer que l'application répond à vos objectifs.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="services-details-image">
                                        <Lottie
                                            options={defaultOptions}
                                            height="100%"
                                            width="100%"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Plateformes</h3>
                                        <ul>
                                            <li>Android</li>
                                            <li>iOS</li>
                                            <li>Tablette</li>
                                            <li>Smartphone</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <p>Avoir sa propre application comporte de nombreux avantages.</p> */}
                            <h3>Secteurs d'Application</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Industrie
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Santé
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automobile
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Boutique
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Immobilier
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistique
                                    </div>
                                </div>
                            </div>

                            <h3>Combien ça coûte?</h3>
                            <p>Le développement d'une application mobile prend généralement plus de temps que le développement d'un site Web. Cela dépendra donc entièrement du cahier des charges qui doit être défini avant de démarrer le projet. Une fois le cahier des charges établi, nous pouvons vous donner une estimation du coût de développement.</p>

                            <h3>Les Technologies Utilisées</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>React Native</span></li>
                                <li><span>Expo</span></li>
                                <li><span>Android</span></li>
                                <li><span>Swift</span></li>
                                <li><span>Angular</span></li>
                                <li><span>Node.js</span></li>
                                <li><span>Firebase</span></li>
                                <li><span>MongoDB</span></li>
                            </ul>
                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceMobileContent